import React from "react";
import { useNavigate } from "react-router-dom";

export default function Page404() {
    const navigate = useNavigate()
    return (
        <div className="error-div">
            <img src={require('../../src/images/error404.webp')} />
            <h1>Oops Page not found!</h1>
            <button className="btn btn-primary" onClick={() => navigate('/')}>Go to Home</button>
        </div>
    )
}