import React, { useState } from "react";
import "../modals/ChooseAvatar.scss";
// import abc from "../modals/ChooseAvatar.scss"
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
// import { handleModel } from "../../redux/layoutSlices/modelSlice";
import ImageskeletonLoader from "../imageLoader/ImageskeletonLoader";
import { handleModel } from "../modelSlice";

export default function ChooseAvatarModal({ selectedAvatar }) {
  const [selectedImage, setSelectedImage] = useState();
  const d = useDispatch();
  const modelsArgs = useSelector(
    (state) => state.model?.modelArgs?.chooseAvatar
  );

  const imagesData = {
    "10-Climate-Patch": [
      "10-Climate-Patch/Avalanche.png",
      "10-Climate-Patch/Blizzard.png",
      "10-Climate-Patch/Flood.png",
      "10-Climate-Patch/Gale.png",
      "10-Climate-Patch/Hail.png",
      "10-Climate-Patch/Hurricane.png",
      "10-Climate-Patch/Lightning.png",
      "10-Climate-Patch/Monsoon.png",
      "10-Climate-Patch/Rainbow.png",
      "10-Climate-Patch/Sandstorm.png",
      "10-Climate-Patch/Thunder.png",
      "10-Climate-Patch/Tornado.png",
      "10-Climate-Patch/Tsunami.png",
      "10-Climate-Patch/Wildfire.png",
    ],

    "11-Monuments": [
      "11-Monuments/Aqsa Mosque.png",
      "11-Monuments/Azhar Mosque.png",
      "11-Monuments/Cordoba Mosque.png",
      "11-Monuments/Faisal Mosque.png",
      "11-Monuments/Great Mosque of Samarra.png",
      "11-Monuments/Masjid al-Qiblatayn.png",
      "11-Monuments/Masjid Haram.png",
      "11-Monuments/Masjid Nabawi.png",
      "11-Monuments/Masjid Quba.png",
      "11-Monuments/Pyramid of Giza.png",
      "11-Monuments/Taj Mahal.png",
      "11-Monuments/The Alhambra.png",
      "11-Monuments/The Blue Mosque.png",
      "11-Monuments/The Citadel of Aleppo.png",
    ],

    "1-Planetary-Patch": [
      "1-Planetary-Patch/Earth.png",
      "1-Planetary-Patch/Jupiter.png",
      "1-Planetary-Patch/Lunar.png",
      "1-Planetary-Patch/Mars.png",
      "1-Planetary-Patch/Mercury.png",
      "1-Planetary-Patch/Merdian.png",
      "1-Planetary-Patch/Neptune.png",
      "1-Planetary-Patch/Orbiter.png",
      "1-Planetary-Patch/Pluto.png",
      "1-Planetary-Patch/Satellite.png",
      "1-Planetary-Patch/Saturn.png",
      "1-Planetary-Patch/Solar.png",
      "1-Planetary-Patch/Voyager.png",
      "1-Planetary-Patch/Zeneth.png",
    ],

    "2-Interstellar-Patch": [
      "2-Interstellar-Patch/Asteroid.png",
      "2-Interstellar-Patch/Astronaut.png",
      "2-Interstellar-Patch/Black Hole.png",
      "2-Interstellar-Patch/Comet.png",
      "2-Interstellar-Patch/Constellation.png",
      "2-Interstellar-Patch/Cosmos.png",
      "2-Interstellar-Patch/Galaxy.png",
      "2-Interstellar-Patch/Gravity.png",
      "2-Interstellar-Patch/Horizon.png",
      "2-Interstellar-Patch/Light Speed.png",
      "2-Interstellar-Patch/Milky way.png",
      "2-Interstellar-Patch/Nebula.png",
      "2-Interstellar-Patch/Shooting Star.png",
      "2-Interstellar-Patch/Supernov.png",
    ],

    "3-Marine-Patch": [
      "3-Marine-Patch/Arabian Sea.png",
      "3-Marine-Patch/Artic Ocean.png",
      "3-Marine-Patch/Atlantic Ocean.png",
      "3-Marine-Patch/Caribbean Sea.png",
      "3-Marine-Patch/Caspian Sea.png",
      "3-Marine-Patch/Coral Sea.png",
      "3-Marine-Patch/Euphrates River.png",
      "3-Marine-Patch/Group 51599.png",
      "3-Marine-Patch/Indian Ocean.png",
      "3-Marine-Patch/Mediterranean Sea.png",
      "3-Marine-Patch/Mississippi River.png",
      "3-Marine-Patch/Nile River.png",
      "3-Marine-Patch/Red Sea.png",
      "3-Marine-Patch/The Great Lakes.png",
    ],

    "4-Pinnacle-Patch": [
      "4-Pinnacle-Patch/Altitude.png",
      "4-Pinnacle-Patch/Denali.png",
      "4-Pinnacle-Patch/Grand Canyon.png",
      "4-Pinnacle-Patch/Himalays.png",
      "4-Pinnacle-Patch/Mount Elbrus.png",
      "4-Pinnacle-Patch/Mount Everest.png",
      "4-Pinnacle-Patch/Mount Fuji.png",
      "4-Pinnacle-Patch/Mount Kilimanjaro.png",
      "4-Pinnacle-Patch/Mount Rainier.png",
      "4-Pinnacle-Patch/Peak.png",
      "4-Pinnacle-Patch/Rocky Mountains.png",
      "4-Pinnacle-Patch/The Alps.png",
      "4-Pinnacle-Patch/Yellow Stone.png",
      "4-Pinnacle-Patch/Yosemite.png",
    ],

    "5-Blossom-Patch": [
      "5-Blossom-Patch/Clover.png",
      "5-Blossom-Patch/Daffodil.png",
      "5-Blossom-Patch/Daisy.png",
      "5-Blossom-Patch/Dandelion.png",
      "5-Blossom-Patch/Flora.png",
      "5-Blossom-Patch/Garden.png",
      "5-Blossom-Patch/Ivy.png",
      "5-Blossom-Patch/Jasmine.png",
      "5-Blossom-Patch/Lavender.png",
      "5-Blossom-Patch/Lily.png",
      "5-Blossom-Patch/Meadow.png",
      "5-Blossom-Patch/Rose.png",
      "5-Blossom-Patch/Sage.png",
      "5-Blossom-Patch/Tulip.png",
    ],

    "6-Terra-Patch": [
      "6-Terra-Patch/Canyon.png",
      "6-Terra-Patch/Desert.png",
      "6-Terra-Patch/Flat Land.png",
      "6-Terra-Patch/Forest.png",
      "6-Terra-Patch/Glacier.png",
      "6-Terra-Patch/Hill.png",
      "6-Terra-Patch/Marshe.png",
      "6-Terra-Patch/Mountain.png",
      "6-Terra-Patch/Oasis.png",
      "6-Terra-Patch/Ocean.png",
      "6-Terra-Patch/Stream.png",
      "6-Terra-Patch/Swamp.png",
      "6-Terra-Patch/Tundra.png",
      "6-Terra-Patch/Valley.png",
    ],

    "7-Earth-Patch": [
      "7-Earth-Patch/Aquifier.png",
      "7-Earth-Patch/Atmosphere.png",
      "7-Earth-Patch/Bedrock.png",
      "7-Earth-Patch/Cave.png",
      "7-Earth-Patch/Compass.png",
      "7-Earth-Patch/Continent.png",
      "7-Earth-Patch/EarthQuake.png",
      "7-Earth-Patch/Fissure.png",
      "7-Earth-Patch/Mantle.png",
      "7-Earth-Patch/Mineral.png",
      "7-Earth-Patch/North Pole.png",
      "7-Earth-Patch/Radius.png",
      "7-Earth-Patch/South Pole.png",
      "7-Earth-Patch/Volcano.png",
    ],

    "8-Timber-Patch": [
      "8-Timber-Patch/Aspen.png",
      "8-Timber-Patch/Birch.png",
      "8-Timber-Patch/Cedar.png",
      "8-Timber-Patch/Cherry.png",
      "8-Timber-Patch/Cyprus.png",
      "8-Timber-Patch/Elm.png",
      "8-Timber-Patch/Fig.png",
      "8-Timber-Patch/Fir.png",
      "8-Timber-Patch/Juniper.png",
      "8-Timber-Patch/Maple.png",
      "8-Timber-Patch/Oak.png",
      "8-Timber-Patch/Pine.png",
      "8-Timber-Patch/Spruce.png",
      "8-Timber-Patch/Willow.png",
    ],

    "9-Design-Patch": [
      "9-Design-Patch/Exponential.png",
      "9-Design-Patch/Heptagram.png",
      "9-Design-Patch/Infinity.png",
      "9-Design-Patch/Keystone.png",
      "9-Design-Patch/Kite.png",
      "9-Design-Patch/Mobius Strip.png",
      "9-Design-Patch/Pentagon.png",
      "9-Design-Patch/Prism.png",
      "9-Design-Patch/Pyramid.png",
      "9-Design-Patch/Rhomboid.png",
      "9-Design-Patch/Sheild.png",
      "9-Design-Patch/Sphere.png",
      "9-Design-Patch/Trilliant.png",
      "9-Design-Patch/Whistle.png",
    ],
  };

  const handleImages = (imageItem) => {
    if (modelsArgs?.pictures && modelsArgs?.pictures.length) {
      if (modelsArgs?.pictures?.some((k) => k == imageItem)) {
        return modelsArgs.pictures?.filter((k) => k != imageItem);
      } else {
        return [...modelsArgs?.pictures, imageItem];
      }
    }
    return [imageItem];
  };

  const handleAvatarClick = (imageItem) => {
    setSelectedImage(imageItem);
  };

  const closeModel = (args) => {
    if (modelsArgs?.callBack) modelsArgs.callBack(args[modelsArgs.type]);
    d(handleModel({ model: "chooseAvatar", state: false }));
  };

  const ProfileHandler = () => {
    const args = {
      ...modelsArgs,
      ...(modelsArgs?.type == "picture"
        ? { picture: selectedImage }
        : { pictures: handleImages(selectedImage) }),
    };

    if (modelsArgs?.type === "picture") {
      d(
        handleModel({
          model: "chooseAvatar",
          state: true,
          args,
        })
      );
      closeModel(args);
    }
  };

  return (
    <div className="choose-avatar-main">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <strong>{selectedAvatar?.folderName}</strong>
        </Modal.Title>
      </Modal.Header>
      <div className="flex-box-four">
        <div className="_avatars_collection">
          <div className="row">
            <div className="avatar-images">
              {imagesData?.[selectedAvatar?.folderKey]?.map(
                (imagePath, index) => {
                  return (
                    <div className="Avatars" key={index}>
                      {/* <ChooseAvatarCard imageItem={imagePath} index={index} /> */}
                      {/* <span> {imagePath?.content}</span> */}

                      <div className="col-lg-2">
                        <div
                          className="avatar-box"
                          onClick={() => handleAvatarClick(imagePath)}
                        >
                          <ImageskeletonLoader
                            imgClassName={"avatar-img"}
                            src={imagePath}
                            parseAvatarUrl={true}
                            parseUrl={false}
                            title={false}
                            imgLoaderWidth={"60"}
                          />
                          {imagePath === selectedImage ? (
                            <div
                              className="circle-icon"
                              style={{
                                position: "absolute",
                                top: "-3px",
                                left: "-5px",
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="112"
                                height="112"
                                viewBox="0 0 112 112"
                                fill="none"
                              >
                                <path
                                  d="M110.675 55.8376C110.675 86.1236 86.1236 110.675 55.8376 110.675C25.5516 110.675 1 86.1236 1 55.8376C1 25.5516 25.5516 1 55.8376 1C86.1236 1 110.675 25.5516 110.675 55.8376Z"
                                  stroke="#22C55E"
                                  stroke-width="2"
                                />
                                <circle
                                  cx="93.9081"
                                  cy="15.2284"
                                  r="15.2284"
                                  fill="white"
                                />
                                <path
                                  d="M93.9081 0C85.5325 0 78.6797 6.8528 78.6797 15.2284C78.6797 23.6041 85.5325 30.4569 93.9081 30.4569C102.284 30.4569 109.137 23.6041 109.137 15.2284C109.137 6.8528 102.284 0 93.9081 0ZM100.304 12.6396L92.9944 19.9493C92.3853 20.5584 91.4716 20.5584 90.8624 19.9493L87.5122 16.599C86.903 15.9899 86.903 15.0762 87.5122 14.467C88.1213 13.8579 89.035 13.8579 89.6442 14.467L91.9284 16.7513L98.1721 10.5076C98.7812 9.89849 99.6949 9.89849 100.304 10.5076C100.913 11.1168 100.913 12.0305 100.304 12.6396Z"
                                  fill="#22C55E"
                                />
                              </svg>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <ModalPagination /> */}
      <Modal.Footer>
        <Button
          variant="light"
          style={{ width: "15%", padding: "10px ,20px" }}
          onClick={closeModel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          style={{ width: "15%", padding: "10px ,20px" }}
          onClick={ProfileHandler}
        >
          Ok
        </Button>
      </Modal.Footer>
    </div>
  );
}
