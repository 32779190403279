// ** Reducers Imports
import user from "../components/userSlice";
import { combineReducers } from "@reduxjs/toolkit";
import model from "./modelSlice";
const rootReducer = combineReducers({
  user,
  model,
});

export default rootReducer;
