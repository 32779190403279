import React from 'react'
import cross from "../images/croos.svg"
import not from "../images/not.svg"

function NoStudentFound() {
  return (
    <div className='access-denied-page'>
    <div className='access-page-wrapper'>
     <div className='lock-img'>
         <img src={not} alt="" />
         <h2>No Students Found</h2>
         <button>Create Student</button>
     </div>
     <div className='footer-wrapper'>
             <div className='img-inner'>
                 <img src={cross} alt="" />
                 <p className='my-2'>No students, create your first student. </p>
             </div>
         </div>
    </div>
 </div>
  )
}

export default NoStudentFound
