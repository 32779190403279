import React from 'react';
import senderImage from "../images/senderImage.png";
import receiverImage from "../images/receiverImage.png";
import add from "../images/add.svg";
import mic from "../images/mic.svg";

const senderData = {
  dp: senderImage,
  text: "Hello! I’ve been thinking about developing some new skills. Any suggestions on where to start?"
};

const receiverData = {
  dp: receiverImage,
  text: "Hello Bahaji! I’ll help you build a new chat AI. What would you like to create?"
};

const EngageMessagePage = () => {
  return (
    <div className='dm-main-wrapper'>
      <div className="container-fluid">
        <div className="dm-parent">
          <div className="sender-message">
            <div className="wrapper">
              <img src={senderData.dp} alt="Sender" className='sender-dp' />
              <p>{senderData.text}</p>
            </div>
          </div>
          <div className="receiver-message">
            <div className="wrapper">
              <img src={receiverData.dp} alt="Receiver" className='receiver-dp' />
              <p>{receiverData.text}</p>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-container">
              <span className="icon-start">
                <img src={add} alt="add" />
              </span>
              <input
                type="text"
                className="input-field"
                placeholder="Type a message..."
              />
              <span className="icon-end">
                <img src={mic} alt="mic" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngageMessagePage;
