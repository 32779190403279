import { APIurls } from "../constants.js";
import apiService from ".";
import multipart from "./multipart";

export const ApiRequests = {
  // auth
  createUser: async (data) => await apiService.post(APIurls.createUser, data),
  forgotPassword: async (data) =>
    await apiService.post(APIurls.forgetPassword, data),
  resetPassword: async ({ data, token }) =>
    await apiService.post(APIurls.resetPassword, data, { params: { token } }),
  getUserWithEmail: async (email) =>
    await apiService.get(APIurls.getUserWithEmail + "/?email=" + email),
  // organization
  importScriptTriger: async () => await apiService.get(APIurls.importScripts),
  createOrganization: async (data) =>
    await apiService.post(APIurls.organizations, data),
  deleteOrganization: async (id) =>
    await apiService.delete(APIurls.organizations + "/" + id),
  getOrganizations: async (params) =>
    await apiService.get(APIurls.organizations, { params }),
  getOrganization: async (name) =>
    await apiService.get(APIurls.organizations + "/" + name + "/name"),
  getOrganizationsByMail: async (params) =>
    await apiService.get(APIurls.bymail, { params }),
  getOrganizationsMembership: async (params) =>
    await apiService.get(APIurls.membership, { params }),
  // authors
  getAuthorsMembership: async (params) =>
    await apiService.get(APIurls.authorsMembership, { params }),
  createAuthor: async (data) => await apiService.post(APIurls.authors, data),
  // updateProfile: async ({ id, data }) => await apiService.patch(APIurls.profile + "/" + id, data),
  updateProfile: async ({ data }) =>
    await apiService.patch(APIurls.profile, data),
  // getProfile: async (id) => await apiService.get(APIurls.profile + "/" + id),
  getProfile: async (token) =>
    await apiService.get(APIurls.profile, {
      headers: { ...(token ? { Authorization: "Bearer " + token } : {}) },
    }),

  //
  createContent: async ({ data, options }) =>
    await multipart.post(APIurls.userContents, data, options),
  getCustomToken: async ({ uid }) =>
    await apiService.post(APIurls.auth + "/customToken", { uid }),
  // invitations
  inviteSuccess: async (data) =>
    await apiService.post(APIurls.invitesSuccess, data),
  authorInviteSuccess: async (data) =>
    await apiService.post(APIurls.authorInvitesSuccess, data),
  getOwner: async (user_id) =>
    await apiService.get(APIurls.members + "/" + user_id),
  updateOwner: async ({ data, id }) =>
    await apiService.patch(APIurls.members + "/" + id, data),
};
