import AOS from "aos";
import "aos/dist/aos.css";
import CreateOrganization from "../components/CreateOrganization";

AOS.init();
export default function OnBoardingFlow() {
  return (
    <div>
      <div className="auth-body-outer" id="authBodyOuter">
        <CreateOrganization />
      </div>
    </div>
  );
}
