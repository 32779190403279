import React from 'react'
import cross from "../images/croos.svg"
import fish from "../images/fish.svg"

function ComingSoon() {
  return (
    <div className='access-denied-page'>
    <div className='access-page-wrapper'>
     <div className='lock-img'>
         <img src={fish} alt="" />
         <h2>Coming Soon</h2>
         <button>Go Back</button>
     </div>
     <div className='footer-wrapper'>
             <div className='img-inner'>
                 <img src={cross} alt="" />
                 <p className='my-2'>This feature will come very soon!</p>
             </div>
         </div>
    </div>
 </div>
  )
}

export default ComingSoon
