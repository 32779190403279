import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { auth } from "../config/firebase";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ApiRequests } from "../service/ApiRequests";
import ForgetPassword from "../components/ForgetPassword";
import {
  GoogleAuthProvider,
  OAuthProvider,
  confirmPasswordReset,
  signInWithPopup,
} from "firebase/auth";
import ProgressBar from 'react-bootstrap/ProgressBar';


AOS.init();
export default function Reset() {
  const [getParams] = useSearchParams();
  const oobCode = getParams.get("oobCode");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [forget, setForget] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [invisible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const calculatePasswordStrength = (password) => {
    // Implement your password strength calculation logic here
    // Example: Check for length, uppercase, lowercase, numbers, and special characters
    let strength = 0;

    // Check for length
    if (password.length >= 8) {
      strength += 25;
    }

    // Check for uppercase, lowercase, numbers, and special characters
    const regexList = [/.*[a-z].*/, /.*[A-Z].*/, /.*\d.*/, /.*[!@#$%^&*()_+].*/];

    regexList.forEach((regex) => {
      if (regex.test(password)) {
        strength += 25;
      }
    });

    return strength;
  };

  const handlePasswordChange = (e) => {
    setError(null)
    const newPassword = e.target.value;
    setData({ ...data, password: newPassword });
    const strength = calculatePasswordStrength(newPassword);
    setPasswordStrength(strength);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (data.password !== data.confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    setValidated(true);
    setLoading(true);
    try {
      await auth.confirmPasswordReset(oobCode, data.password);
      const strength = calculatePasswordStrength(data.password);
      setPasswordStrength(strength);

      // Navigate only if the password is strong enough (adjust the threshold as needed)
      if (strength >= 75) {
        navigate("/signin");
        console.log("User logged in successfully!");
      } else {
        setError("Password is too weak. Please choose a stronger password.");
      }
      // navigate('/');
      navigate("/signin");
      console.log("User logged in successfully!");
    } catch (error) {
      setError(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!oobCode) navigate('/signin')
    if (auth.currentUser) {
      navigate("/signup/organization");
    }
  }, [navigate, auth.currentUser]);

  console.log("error", error);

  return (
    <>
      <div>
        <div>
          <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="accounts-body">
                <img
                  className="accounts-logo"
                  src={require("../images/logo/logo.png")}
                  alt="logo"
                />
                <div className="create-account-card">
                  <h1 style={{ marginBottom: "0px" }}>Reset Password</h1>
                  <div className="terms-privacy mb-4">
                    {/* For the account bahaji@ilmiya.com */}
                  </div>
                  <div className="form-card">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-group">
                          <Form.Control
                            name="password"
                            className="form-control"
                            required
                            value={data?.password}
                            // onChange={(e) =>
                            //   setData({ ...data, password: e.target.value })
                            // }
                            onChange={handlePasswordChange}
                            type={showPassword ? "text" : "password"}
                            placeholder="New Password"
                          />
                          <i className="fa-light fa-eye" onClick={() => setShowPassword(!showPassword)}></i>
                        </div>
                        <ProgressBar style={{ height: 8 }} now={passwordStrength}>
                          <ProgressBar
                            style={{
                              backgroundColor: passwordStrength < 50
                                ? "#fd5c5c" // Red for weak
                                : passwordStrength < 75
                                  ? "#f3a836" // Yellow for medium
                                  : "#4cc05c", // Green for strong
                              height: "100%",
                            }}
                            now={passwordStrength}
                          />
                        </ProgressBar>
                      </div>
                      <Form.Label>Your password must be at least 8 characters, and can't begin or end with a space.</Form.Label>

                      <div className="col-lg-12">
                        <div className="input-group">
                          <Form.Control
                            name="confirmPassword"
                            className="form-control"
                            required
                            value={data?.confirmPassword}
                            onChange={(e) => {
                              setData({ ...data, confirmPassword: e.target.value })
                              setError(null)
                            }}
                            type={invisible ? "text" : "password"}
                            placeholder="Confirm Password"
                          />
                          <i className="fa-light fa-eye" onClick={() => setVisible(!invisible)} ></i>
                        </div>
                      </div>

                    </div>
                  </div>
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn btn-primary"
                  >
                    {loading ? <Spinner /> : "Next"}
                  </button>

                  <div className="help-center mt-5">
                    <a href="https://docs.ilmiya.com">Help</a>
                    <span>|</span>
                    <a href="https://ilmiya.com/legal/privacy-policy">Privacy</a>
                    <span>|</span>
                    <a href="https://ilmiya.com/legal/terms-of-service">Terms</a>
                  </div>
                  {error && (
                    <div className="wrong-pass-card">
                      <i className="fa-solid fa-circle-xmark"></i>
                      <span>
                        {error}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
