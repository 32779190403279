import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { auth } from "../config/firebase";
import { useNavigate, useSearchParams } from "react-router-dom";
export default function Verify() {
  const [getParams] = useSearchParams();
  const oobCode = getParams.get("oobCode");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setLoading(true);
    try {
      await auth.applyActionCode(oobCode);
      navigate("/signin");
      console.log("User logged in successfully!");
    } catch (error) {
      setError(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!oobCode) navigate("/signin");
    else handleSubmit();
  }, [navigate, auth.currentUser]);

  console.log("error", error);

  return (
    <>
      <div>
        <div>
          <div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="accounts-body">
                <img
                  className="accounts-logo"
                  src={require("../images/logo/logo.png")}
                  alt="logo"
                />
                <div className="create-account-card">
                <h1 style={{ marginBottom: "0px" }}>Verify Email</h1>
                  <div className="terms-privacy mb-4">
                  We have sent out a confirmation code to <br/> bahaji@ilmiya.com
                  </div>
                  {/* <div className='form-card'>
                    <div className='form-code'>
                        <input type="text" className='form-control' />
                        <input type="text" className='form-control' />
                        <input type="text" className='form-control' />
                        <input type="text" className='form-control' />
                        <input type="text" className='form-control' />
                        <input type="text" className='form-control' />
                    </div>
                </div> */}
                <button className='btn btn-primary'>
                    Verifying
                </button>
                <div className='already-have-account'>
                    Didn't receive code?<a href=''> Resend code <i className="fa-regular fa-arrow-right"></i></a>
                </div>
                  <div className="help-center mt-5">
                    <a href="https://docs.ilmiya.com">Help</a>
                    <span>|</span>
                    <a href="https://ilmiya.com/legal/privacy-policy">Privacy</a>
                    <span>|</span>
                    <a href="https://ilmiya.com/legal/terms-of-service">Terms</a>
                  </div>
                  {error && (
                    <div className="wrong-pass-card">
                      <i className="fa-solid fa-circle-xmark"></i>
                      <span>
                        Wrong password. Try again or click Forgot password.
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
