import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics } from "../../config/firebase"; // Adjust the path as needed

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    analytics.logEvent("page_view", {
      page_path: location.pathname,
      page_title: document.title,
    })
    console.log("Page view logged");
  }, [location]);
};

export default useAnalytics;
