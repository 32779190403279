import React from 'react'
import page from "../images/page.png"
import cross from "../images/croos.svg"

function PageNot() {
  return (
    <div className='access-denied-page'>
       <div className='access-page-wrapper'>
        <div className='lock-img'>
            <img src={page} alt="" />
            <h2>That page doesn’t exist.</h2>
            <button>Go Home</button>
        </div>
        <div className='footer-wrapper'>
                <div className='img-inner'>
                    <img src={cross} alt="" />
                    <p className='my-2'>The page you were looking for couldn’t be found.</p>
                </div>
            </div>
       </div>
       
    </div>
  )
}

export default PageNot
