import React from 'react'
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function CustomButton({
  reducer,
  action,
  title,
  onClick,
  loadingText,
  ...props
}) {
  const loading = useSelector((state) => state[reducer]?.loadings[action]);
  return (
    <Button {...props} disabled={loading} onClick={onClick}>{loading ? (loadingText ?? "Loading...") : title}</Button>
  )
}
