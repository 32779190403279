import React, { useEffect, useState } from "react";
import logo from "../images/logo/logo.png";
import filter from "../images/organization/filter.svg";
import mark from "../images/organization/mark.svg";
import plus from "../images/organization/plus.svg";
import downarrow from "../images/organization/downarrow.svg";
import rightArrow from "../images/right-arrow.png";
import leftArrow from "../images/left-arrow.png";
import { ApiRequests } from "../service/ApiRequests";
import { redirectToAuthor } from "../constants";
import { auth } from "../config/firebase";
import { Link, useNavigate } from "react-router-dom";
import Logout from "../components/logout";
import { PageLoader } from "./PageLoader";
import Form from 'react-bootstrap/Form';
import { CgOrganisation } from "react-icons/cg";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { countUserEvent } from "../utils/utlis";



export default function Authors() {
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState("asce");
  const [token, setToken] = useState(null);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [inviteLoading, setInviteLoading] = useState(false)
  const navigate = useNavigate();
  const totalResults = orgs.totalResults || 0;
  const totalPages = orgs.totalPages || 1;


  const toggleOrder = () => {
    if (orgs.length === 0) {
      setOrder('asce')
    } else {
      setOrder(order === "asce" ? "desc" : "asce");
    }
  };

  const fetchToken = async () => {
    try {
      const { data } = await ApiRequests.getCustomToken({
        uid: auth.currentUser.uid,
      });
      setToken(data.customToken);
      return data.customToken;
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };

  const getAuthorsMembership = async () => {
    try {
      setLoading(true);
      const params = {
        page: currentPage,
        limit: itemsPerPage,
        ...(debouncedSearchValue && { name: debouncedSearchValue }),
        sortBy: `Author.name:${order}`,
      };
      const { data } = await ApiRequests.getAuthorsMembership(params);
      console.log(data, "data");
      setOrgs(data);
    } catch (error) {
      console.log("error:getAuthorsMembership:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedSearchValue(searchValue);
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [searchValue]);

  const checkInvite = async () => {
    const invite = localStorage.getItem("invite");
    const authorInvite = localStorage.getItem("author-invite");
    if (invite) {
      try {
        setInviteLoading(true)
        await ApiRequests.inviteSuccess({ token: invite })
        navigate("/organizations");
      } catch (e) {
        // console.log(e)
      } finally {
        localStorage.removeItem("invite");
        setInviteLoading(false)
      }
    }
    if (authorInvite) {
      try {
        setInviteLoading(true)
        await ApiRequests.authorInviteSuccess({ token: authorInvite })
        navigate("/authors");
        getAuthorsMembership();

      } catch (e) {
        // console.log(e)
      } finally {
        setInviteLoading(false)
        localStorage.removeItem("author-invite");

      }
    }

  }
  useEffect(() => {
    fetchToken();
    checkInvite()
  }, []);
  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/signin');
    }
  }, [navigate, auth.currentUser])


  useEffect(() => {
    getAuthorsMembership();
  }, [currentPage, itemsPerPage, debouncedSearchValue, order]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = async (event) => {
    event.preventDefault();
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
    getAuthorsMembership();
  };

  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(startItem + itemsPerPage - 1, totalResults);

  return (
    <div>
      <Logout />
      {inviteLoading ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>Accepting Invite...</div> :
        <div className="organzation-outer">
          <div className="container">
            {/* <img loading="lazy" src={logo} className="img" alt="logo" /> */}
            <div className="inner">
              <span className="main-org">
                <div className="org-head">
                  <h2>Publishers</h2>

                </div>
                <div className="d-flex align-items-center gap-3">
                  <div className="div-9">
                    {/* <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip  id="tooltip-top" style={{position:"fixed"}}>
                        Go To Organization
                      </Tooltip>
                    }
                  >
                    <Link onClick={()=>countUserEvent('Go_To_Organization' , {eventName : "Go_To_Organization"})} to="/organizations">
                      <CgOrganisation />
                    </Link>
                  </OverlayTrigger> */}


                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top" style={{ position: "fixed" }}>
                          Add Publisher
                        </Tooltip>
                      }
                    >
                      <Link onClick={() => countUserEvent('Add_Publisher', { eventName: "Add_Publisher" })} to="/authors/create">
                        <div className="div-10">
                          <img loading="lazy" src={plus} className="img-8" alt="add Publisher" />
                        </div>
                      </Link>
                    </OverlayTrigger>

                  </div>
                </div>
              </span>
              <div className="org-filter">
                <div className="filter">
                  <span className="filter-outer">
                    <img
                      loading="lazy"
                      src={filter}
                      onClick={toggleOrder}
                      style={{ cursor: "pointer" }}
                      alt="filter"
                    />
                    <input
                      className="input"
                      type="text"
                      placeholder="Filter Publisher"
                      value={searchValue}
                      onChange={handleSearchChange}
                    />
                  </span>
                </div>
                <div className="org-feild">
                  <span className="feild" onClick={toggleOrder}>
                    <div className="feild-content">Publisher Name</div>
                    <img loading="lazy" src={downarrow} className="img-9" alt="sort" />
                  </span>
                  <span className="domain-outer" onClick={toggleOrder}>
                    <div className="do-content">Created At</div>
                    <img loading="lazy" src={downarrow} className="img-10" alt="sort" />
                  </span>
                </div>

                {loading ?
                  <PageLoader />
                  // <h4 className="text-center my-5">Loading Organizations...</h4>

                  : (orgs && orgs.results && orgs.results.length) ?
                    orgs?.results?.map((org, i) => (
                      <span className="id-outer" key={i}>
                        <a
                          href={`${redirectToAuthor(org?.Author?.author_id)}?token=${token}`}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <span className="id-inner">
                            <img
                              loading="lazy"
                              src={org?.Author?.profile_image_url}
                              className="img-11"
                              alt="author"
                            />
                            <div className="id-content">{org?.Author?.name}</div>
                          </span>
                        </a>
                        <div className="id-mail">
                          @{org?.author_id}
                        </div>
                      </span>
                    ))
                    :
                    <h4 className="text-center my-5">No Publisher found</h4>
                }
              </div>
              <div className="pagination-wrapper">
                <span>Rows per page:</span>
                <Form.Select
                  aria-label="Default select example"
                  className="all"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                </Form.Select>
                <span>{`${startItem} - ${endItem} of ${totalResults}`}</span>
                <div className="d-flex align-items-center gap-3">
                  <img src={leftArrow} alt="leftArrow" width={10} onClick={handlePreviousPage} />
                  {/* {renderPagination()} */}
                  <img src={rightArrow} alt="rightArrow" width={10} onClick={handleNextPage} />
                </div>
              </div>
            </div>
          </div>

        </div>
      }
    </div>
  );
}
