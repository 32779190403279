import { Box, TextField } from "@mui/material";
import React, { useState } from "react";
import { Dropdown, FloatingLabel, Form } from "react-bootstrap";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ErrorMassage from "../components/ErrorMassage";

// import Sidebar from "../components/Sidebar";
// import Clock from "../assets/images/icons/clock.svg";
import profile from "../images/profile.png";
import edit from "../images/edit.svg";
import inboxIcon from "../images/inboxIcon.svg";
import dropdownIcon from "../images/dropdown-icon.svg";
import DropdownWithIcon from "../components/DropdownWithIcon";
import HeaderBottomOpenPage from "../components/HeaderBottomOpenPage";
import ChangePasswordModal from "../components/ChangePasswordModal";
import ProfileCard from "./ProfileCard";
// import Select from "react-select";
// import Flag from "react-world-flags";
// import flag from "../assets/images/icons/usa-icon.svg";
// import PageBreadcrumb from "../components/PageBreadcrumb";
// import settingsIcon from "../assets/images/icons/settings-icon.svg"

export default function ProfileForm({
  handleChange,
  handleChange1,
  values,
  errors,
  touched,
  user,
  show,
  handleSubmit,
  setPhotoURL,
  photoURL,
}) {
  const [isShow, setIsShow] = useState(false);
  const [time, setTime] = useState("4:17 AM (GMT-6)");
  // const [close,setClose] =useState(false)

  const [showModalPasswordChange, setShowModalPasswordChange] = useState(false);

  const handleShow = () => setIsShow(true);
  const handleClose = () => setIsShow(false);

  const [activeTab, setActiveTab] = useState("tab1");
  const getButtonClass = (tabName) => {
    setActiveTab(tabName);
  };

  console.log("valueesss::::::::", values);

  const [selected, setSelected] = useState(values?.gender || "Select Gender");

  const handleSelect = (eventKey) => {
    setSelected(eventKey);
    handleChange({
      target: {
        name: name || "gender",
        value: eventKey,
      },
    });
  };

  return (
    <>
      {/* <div className="profile-form">
        <div className="Connection">
          <div className="form-wrapper">
            <Box
              component="form"
              sx={{ "& > :not(style)": { m: 1, width: "100%" } }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  controlId="outlined-basic"
                  label="First name"
                  name="given_name"
                  variant="outlined"
                  value={values?.given_name}
                  className="mb-3"
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: "given_name",
                        value: e.target.value,
                      },
                    });
                    handleChange1(e);
                  }}
                  type="text"
                />
                <ErrorMassage
                  error={errors?.given_name}
                  visible={touched?.given_name}
                />
              </div>

              <div>
                <TextField
                  controlId="outlined-basic"
                  label="Last name"
                  name="family_name"
                  variant="outlined"
                  className="mb-3"
                  type="text"
                  placeholder="Last Name"
                  value={values?.family_name}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: "family_name",
                        value: e.target.value,
                      },
                    });
                    handleChange1(e);
                  }}
                />
                <ErrorMassage
                  error={errors?.family_name}
                  visible={touched?.family_name}
                />
              </div>

              <div>
                <TextField
                  controlId="outlined-basic"
                  label="Username"
                  variant="outlined"
                  className="mb-3"
                  disabled
                  value={values?.username}
                />
              </div>

              <div>
                <TextField
                  controlId="outlined-basic"
                  label="id no."
                  variant="outlined"
                  className="mb-3"
                  type="text"
                  name="id_card_number"
                  placeholder="Id"
                  value={values?.id_card_number}
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: "id_card_number",
                        value: e.target.value,
                      },
                    });
                    handleChange1(e);
                  }}
                />
                <ErrorMassage
                  error={errors?.id_card_number}
                  visible={touched?.id_card_number}
                />
              </div>

              <div>
                <TextField
                  controlId="outlined-basic"
                  label="Email"
                  variant="outlined"
                  value={values?.email}
                  className="mb-3"
                  defaultValue={"bahaji@ilmiya.com"}
                  disabled={true}
                />
              </div>

              <div>
                <FloatingLabel
                  controlId="floatingPassword"
                  label="Password"
                  className="mb-3"
                  style={{ position: "relative" }}
                >
                  <Form.Control
                      
                    name="password"
                    value={values?.password}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: "password",
                          value: e.target.value,
                        },
                      });
                      handleChange1(e);
                    }}
                    placeholder="Password"
                  />
                  <div
                    className="eye-icon"
                    style={{ position: "absolute", top: "19px", right: "19px" }}
                  >
                    {!show ? (
                      <FaEye onClick={() => setShow(!show)} />
                    ) : (
                      <FaEyeSlash onClick={() => setShow(!show)} />
                    )}
                  </div>
                </FloatingLabel>
              </div>

              <div>
                <TextField
                  controlId="outlined-basic"
                  label="Date oF Birth"
                  variant="outlined"
                  className="mb-3"
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: "dob",
                        value: e.target.value,
                      },
                    });
                    handleChange1(e);
                  }}
                  type="Date"
                  name="dob"
                  value={values.dob ? values?.dob.split("T")[0] : ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <ErrorMassage error={errors?.dob} visible={touched?.dob} />
              </div>

              <div>
              <FormControl fullWidth>
                <InputLabel id="gender-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="gender-simple-select-label"
                  id="gender-simple-select"
                  value="Gender"
                  name="gender"
                  label="Gender"
                  className="mb-3"
                >
                  <MenuItem value={'male'}>Male</MenuItem>
                  <MenuItem value={'female'}>Female</MenuItem>
                </Select>
              </FormControl>
            </div>

              <div>
                <FormControl fullWidth>
                  <InputLabel id="gender-simple-select-label">
                    Gender
                  </InputLabel>
                  <Select
                    labelId="gender-simple-select-label"
                    id="gender-simple-select"
                    value={values?.gender}
                    name="gender"
                    label="Gender"
                    className="mb-3"
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: "gender",
                          value: e.target.value,
                        },
                      });
                      handleChange1(e);
                    }}
                  >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Box>
          </div>
        </div>
      </div> */}

      <div className="settings-page-wrapper">
        <HeaderBottomOpenPage
          activeTab={activeTab}
          getButtonClass={getButtonClass}
        />
        {/* <PageBreadcrumb pageTitle={"Settings"} icon={settingsIcon}/> */}
        {activeTab === "tab1" && (
          <>
            <ProfileCard
              show={show}
              user={user}
              handleSubmit={handleSubmit}
              setPhotoURL={setPhotoURL}
              photoURL={photoURL}
              handleChange1={handleChange1}
            />
            {/* <div className="container"> */}
            <div className="container-fluid p-0">
              <div className="page-content new-profile-form">
                {/* <div className="page-title">
                <h1>Profile Overview</h1>
              </div> */}
                <div className="information-cards-wrapper">
                  {/* <div className="single-information-card">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="field-title">
                        <h4>Profile photo</h4>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="information-content">
                        <div className="single-information">
                          <div className="title profile-title">
                            <h5>Profile photo</h5>
                          </div>
                          <div className="profile-image">
                            <img src={profile} alt="profile image" />
                            <button className="edit-btn">
                              <img src={edit} alt="icon" />
                            </button>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                  <div className="single-information-card">
                    <div className="row">
                      <div className="col-4">
                        <div className="info-title">
                          <h3>Personal Info</h3>
                        </div>
                      </div>
                      <div className="col-8">
                        <div className="info-section-wrapper">
                          <div className="single-info">
                            <p>First Name</p>
                            <div>
                              <input
                                type="text"
                                controlId="outlined-basic"
                                label="First name"
                                name="given_name"
                                variant="outlined"
                                value={values?.given_name}
                                className="mb-3"
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "given_name",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                              />
                              <ErrorMassage
                                error={errors?.given_name}
                                visible={touched?.given_name}
                              />
                            </div>
                          </div>
                          <div className="single-info">
                            <p>Last Name</p>
                            <div>
                              <input
                                controlId="outlined-basic"
                                label="Last name"
                                name="family_name"
                                variant="outlined"
                                className="mb-3"
                                type="text"
                                placeholder="Last Name"
                                value={values?.family_name}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "family_name",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                              />
                              <ErrorMassage
                                error={errors?.family_name}
                                visible={touched?.family_name}
                              />
                            </div>
                          </div>
                          <div className="single-info">
                            <p>Username</p>
                            <input
                              controlId="outlined-basic"
                              label="Username"
                              variant="outlined"
                              className="mb-3"
                              disabled
                              value={values?.username}
                            />
                          </div>
                          {/* <div className="single-info">
                          <p>Bio</p>
                          <textarea
                            name=""
                            id=""
                            placeholder="Passionate gardener and food enthusiast, always exploring new recipes and sustainable practices."
                          ></textarea>
                        </div> */}

                          <div className="single-info">
                            <p>id no.</p>
                            <div>
                              <input
                                controlId="outlined-basic"
                                label="id no."
                                variant="outlined"
                                className="mb-3"
                                type="text"
                                name="id_card_number"
                                placeholder="Id"
                                value={values?.id_card_number}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "id_card_number",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                              />
                              <ErrorMassage
                                error={errors?.id_card_number}
                                visible={touched?.id_card_number}
                              />
                            </div>
                          </div>

                          <div className="single-info">
                            <p>Date of Birth</p>
                            <div>
                              <input
                                controlId="outlined-basic"
                                label="Date oF Birth"
                                variant="outlined"
                                className="mb-3"
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "dob",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                                type="Date"
                                name="dob"
                                value={
                                  values.dob ? values?.dob.split("T")[0] : ""
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <ErrorMassage
                                error={errors?.dob}
                                visible={touched?.dob}
                              />
                            </div>
                          </div>
                          {/* <div className="single-info">
                      <p>Country</p>
                      <Select
                        options={countryOptions}
                        formatOptionLabel={formatOptionLabel}
                        placeholder="Select your country"
                        className="flags-field"
                      />
                    </div> */}
                          {/* <div className="single-info">
                              <p>Gender</p>
                              <div>
                              <DropdownWithIcon />
                              </div>
                            </div> */}

                          <div className="single-info">
                            <p>Gender</p>

                            <div className="dropdown-container">
                              <select
                                name="gender"
                                className="custom-dropdown"
                                value={values.gender}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      name: "gender",
                                      value: e.target.value,
                                    },
                                  });
                                  handleChange1(e);
                                }}
                              >
                                
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                              <ErrorMassage
                                error={errors?.gender}
                                visible={touched?.gender}
                              />
                            </div>
                          </div>

                          {/* <div className="single-info">
                          <p>Title</p>
                          <DropdownWithIcon />
                        </div> */}
                          {/* <div className="single-info">
                          <p>Phone</p>
                          <input type="number" placeholder="+1 364 645 6568" />
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="single-information-card">
                  <div className="row">
                    <div className="col-4">
                      <div className="info-title">
                        <h3>Personal Info</h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="info-section-wrapper">
                        <div className="single-info">
                          <p>Address</p>
                          <input type="text" placeholder="169 Hayhurst" />
                        </div>
                        <div className="single-info">
                          <p>Secondary</p>
                          <input type="text" placeholder="LaneWest" />
                        </div>
                        <div className="single-info">
                          <p>City</p>
                          <input type="text" placeholder="Bloomfield" />
                        </div>
                        <div className="single-info">
                          <p>Postal Code</p>
                          <input type="number" placeholder="54926" />
                        </div>
                        <div className="single-info">
                          <p>Title</p>
                          <DropdownWithIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
            {/* </div> */}
          </>
        )}
        {activeTab === "tab2" && (
          <div className="container-fluid p-0">
            <div className="page-content">
              <div className="page-title new-page-title">
                <h1>Profile Overview</h1>
              </div>
              <div className="information-cards-wrapper">
                <div className="single-information-card">
                  <div className="row">
                    <div className="col-4">
                      <div className="info-title">
                        <h3>Login</h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div
                        className="info-section-wrapper "
                        style={{ padding: "0" }}
                      >
                        <div className="head-sec">
                          <span>Login info</span>
                          {/* <div className="input-field">
                            <button onClick={()=>setIsShow(true)}>Change Password</button>
                          </div> */}
                        </div>
                        <div className="single-info-parent">
                          <div className="single-info">
                            <div className="single-info-comp1">
                              <p className="text1">Email</p>
                              <p className="text2">Here are student email</p>
                            </div>
                            <input
                              type="text"
                              placeholder="Thaha@gmail.com"
                              controlId="outlined-basic"
                              variant="outlined"
                              value={values?.email}
                              className="mb-3"
                              disabled={true}
                            />
                          </div>
                          <div className="single-info">
                            <div className="single-info-comp1">
                              <p className="text1">Password</p>
                              <p className="text2">Here are student Password</p>
                            </div>
                            <input
                              type="password"
                              onClick={() => setIsShow(true)}
                              controlId="outlined-basic"
                              variant="outlined"
                              value={"595883"}
                              className="mb-3"
                              //  disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === "tab3" && (
          <div className="container-fluid p-0">
            <div className="page-content">
              <div className="page-title">
                <h1>Profile Overview</h1>
              </div>
              <div className="information-cards-wrapper">
                <div className="single-information-card">
                  <div className="row">
                    <div className="col-4">
                      <div className="info-title">
                        <h3>Notifications</h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="info-section-wrapper">
                        <div className="single-info">
                          <p>Inbox</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                        <div className="single-info">
                          <p>Email</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                        <div className="single-info">
                          <p>Desktop</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className="info-title">
                        <h3>Email Updates</h3>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="info-section-wrapper">
                        <div className="single-info">
                          <p>Invite accepted</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                        <div className="single-info">
                          <p>Privacy and legal updates</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                        <div className="single-info">
                          <p>DPA updates</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                        <div className="single-info">
                          <p>Linear changelog email</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                        <div className="single-info">
                          <p>Linear changelog in sidebard</p>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            className="custom-form"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ChangePasswordModal isShow={isShow} handleClose={handleClose} />
      </div>
    </>
  );
}
