import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Reset from "./Reset";
import Verify from "./Verify";

export default function Actions() {
  const navigate = useNavigate();
  const [getParams] = useSearchParams();
  const mode = getParams.get("mode");
  if (mode === "resetPassword") return <Reset />;
  if (mode === "verifyEmail") return <Verify />;
  return navigate("/signin");
}
