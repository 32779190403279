import Modal from "react-bootstrap/Modal";
import visibilityIcon from "../images/visibilityIcon.svg";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  getProfileAsyncThunk,
  getUserAsyncThunk,
  updateUserAsyncThunk,
} from "./userSlice";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { countUserEvent } from "../utils/utlis";
import { auth } from "../config/firebase";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

function ChangePasswordModal({ isShow, handleClose }) {
  const d = useDispatch();
  const navigate = useNavigate();
  const { user_id } = useParams();

  const [show, setShow] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const user = useSelector((state) => state?.user?.story);
  console.log("user:::", user);

  const PasswordSchema = yup?.object()?.shape({
    password: yup
      .string()
      .required("New password is required")
      .min(6, "Password must be at least 6 characters long"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const initialValues = {
    password: "",
  };

  function handleUpdate(values) {
    console.log("dispatch data::::::", values, user_id);
    d(
      updateUserAsyncThunk({
        data: { password: values?.password },
        id: user_id,
        callBack: () => {
          d(getUserAsyncThunk(user_id));
          d(getProfileAsyncThunk());
          toast.success("Password Updated Successfully!");

          const auth = getAuth();
          const email = user.email; // Get the user's email
          const newPassword = values.password; //

          signInWithEmailAndPassword(auth, email, newPassword)
            .then((userCredential) => {
              console.log(
                "Re-authenticated successfully:",
                userCredential.user
              );
            })
            .catch((error) => {
              console.error("Error during re-authentication:", error.message);
            });

          // Log out the user after password update
          // countUserEvent("Logout", { eventName: "Logout" });
          // auth.signOut().then(() => {
          //   navigate("/");
          // });
        },
      })
    );

    handleClose();
  }

  // const modelArgs = useSelector(
  //   (state) => state.model?.modelArgs?.RightSidebarBase
  // );
  // const oldData = modelArgs?.args?.user;
  // console.log("old data:::", oldData);

  return (
    <Formik
      onSubmit={handleUpdate}
      initialValues={initialValues}
      validationSchema={PasswordSchema}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <Modal
          show={isShow}
          onHide={handleClose}
          centered
          className="custom-modal-password-change"
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit} className="form">
              <div className="form-group">
                <label className="text">Password</label>
                <div className="input-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    className="input-group"
                    onChange={handleChange}
                  />
                  <div
                    className="eye-icon"
                    style={{ position: "absolute", top: "36px", right: "19px" }}
                  >
                    {!showPassword ? (
                      <FaEye onClick={() => setShowPassword(!showPassword)} />
                    ) : (
                      <FaEyeSlash
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                </div>
                {touched?.password && errors?.password && (
                  <div className="modal-error-message">{errors?.password}</div>
                )}
              </div>

              <div className="form-group">
                <label className="text">Confirm Password</label>
                <div className="input-container">
                  <input
                    type={show ? "text" : "password"}
                    name="confirmPassword"
                    className="input-group"
                    onChange={handleChange}
                  />
                  <div
                    className="eye-icon"
                    style={{ position: "absolute", top: "36px", right: "19px" }}
                  >
                    {!show ? (
                      <FaEye onClick={() => setShow(!show)} />
                    ) : (
                      <FaEyeSlash onClick={() => setShow(!show)} />
                    )}
                  </div>
                </div>
                {touched?.confirmPassword && errors?.confirmPassword && (
                  <div className="modal-error-message">
                    {errors?.confirmPassword}
                  </div>
                )}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className="cancel-button" onClick={handleClose}>
              Cancel
            </button>
            <button className="update-button" onClick={handleSubmit}>
              Update
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

export default ChangePasswordModal;
