const localhost = process.env.REACT_APP_NODE_ENV === "localhost";
const development = process.env.REACT_APP_NODE_ENV === "development";
const staging = process.env.REACT_APP_NODE_ENV === "staging";
export const app_mode = localhost
  ? "localhost"
  : development
  ? "development"
  : staging
  ? "staging"
  : "production";
export const domain = localhost
  ? "local"
  : development
  ? "dev"
  : staging
  ? "app"
  : "com";
console.log("REACT_APP_NODE_ENV", process.env.REACT_APP_NODE_ENV);
export const basePath =
  process.env.REACT_APP_basePath ??
  (development
    ? "http://192.168.3.116:6060/iam/api/"
    : staging
    ? "https://api.ilmiya.dev/iam/api/"
    : "https://api.ilmiya.com/iam/api/");
export const userBasePath =
  process.env.REACT_APP_userBasePath ??
  (development
    ? "http://192.168.3.116:6060/users/api/"
    : staging
    ? "https://api.ilmiya.dev/users/api/"
    : "https://api.ilmiya.com/users/api/");
export const contentPath =
  process.env.REACT_APP_contentBasePath ??
  (development
    ? "http://192.168.3.116:6063/content/api"
    : staging
    ? "https://api.ilmiya.dev/content/api/"
    : "https://api.ilmiya.com/content/api/");
    
export const basePathInvite =
  process.env.REACT_APP_basePath_invite ??
  (development
    ? "http://192.168.3.116:6065/users/api/"
    : development
    ? "https://api.ilmiya.dev/users/api"
    : staging
    ? "https://api.ilmiya.app/users/api"
    : "https://api.ilmiya.com/users/api");
console.log("REACT_APP_RESEND", process.env.REACT_APP_RESEND);
export const APIurls = {
  // auth
  createUser: userBasePath + "/auth/signup",
  forgetPassword: userBasePath + "/auth/forget-password",
  resetPassword: userBasePath + "/auth/reset-password",
  getUserWithEmail: userBasePath + "profile/getuser",
  // authors
  authorsMembership: "authors/membership",
  authors: "authors",
  // org
  organizations: "organizations",
  bymail: "organizations/by-mail",
  membership: "organizations/membership",
  profile: userBasePath + "profile",
  importScripts: userBasePath + "importScript/create-new-orgs",
  auth: "auth",
  // content
  contents: contentPath + "contents",
  userContents: contentPath + "user/contents",
  invites: userBasePath + "invites",
  invitesSuccess: userBasePath + "invites/success",
  authorInvitesSuccess: "authors/invites/success",
  members: basePathInvite + "/users",
};
export const space = window.location.hostname.split(".")[0];
console.log("🚀 ~ space:", space);

export const redirect_uris = {
  Login: localhost
    ? `http://localhost:3000/`
    : development
    ? `https://accounts.ilmiya.dev/`
    : staging
    ? `https://accounts.ilmiya.app/`
    : `https://accounts.ilmiya.com/`,
  Accounts: localhost
    ? `http://${space}.us.localhost:3001/`
    : development
    ? `https://${space}.myilmiya.dev/`
    : staging
    ? `https://${space}.myilmiya.app/`
    : `https://${space}.myilmiya.com/`,
  Learn: localhost
    ? `http://${space}.us.localhost:3002/lxp/`
    : development
    ? `https://${space}.myilmiya.dev/lxp/`
    : staging
    ? `https://${space}.myilmiya.app/lxp/`
    : `https://${space}.myilmiya.com/lxp/`,
  Studio: localhost
    ? `http://localhost:3004/engage/${space}/`
    : development
    ? `https://platform.ilmiya.dev/engage/${space}/`
    : staging
    ? `https://platform.ilmiya.app/engage/${space}/`
    : `https://platform.ilmiya.com/engage/${space}/`,
  People: localhost
    ? `http://localhost:3008/people/${space}/`
    : development
    ? `https://platform.ilmiya.dev/people/${space}/`
    : staging
    ? `https://platform.ilmiya.app/people/${space}/`
    : `https://platform.ilmiya.com/people/${space}/`,
  Teacher: localhost
    ? `http://localhost:3013/teacher/${space}/`
    : development
    ? `https://platform.ilmiya.dev/teacher/${space}/`
    : staging
    ? `https://platform.ilmiya.app/teacher/${space}/`
    : `https://platform.ilmiya.com/teacher/${space}/`,
  Analytics: localhost
    ? `http://localhost:3014/analytics/${space}/`
    : development
    ? `https://platform.ilmiya.dev/analytics/${space}/`
    : staging
    ? `https://platform.ilmiya.app/analytics/${space}/`
    : `https://platform.ilmiya.com/analytics/${space}/`,
  Console: localhost
    ? `http://localhost:3015/console/${space}/`
    : development
    ? `https://platform.ilmiya.dev/console/${space}/`
    : staging
    ? `https://platform.ilmiya.app/console/${space}/`
    : `https://platform.ilmiya.com/console/${space}/`,

  Admin: localhost
    ? `http://localhost:3005/admin/${space}/`
    : development
    ? `https://platform.ilmiya.dev/admin/${space}/`
    : staging
    ? `https://platform.ilmiya.app/admin/${space}/`
    : `https://platform.ilmiya.com/admin/${space}/`,
};

// export const redirectToConsole = (space) => localhost ? `http://localhost:3001/console/${space}/` : development ? `https://platform.ilmiya.dev/console/${space}/` : staging ? `https://platform.ilmiya.app/console/${space}/` : `https://platform.ilmiya.com/${space}/`;
export const redirectToConsole = (space) =>
  localhost
    ? `http://localhost:3015/console/${space}/`
    : development
    ? `https://platform.ilmiya.dev/console/${space}/`
    : staging
    ? `https://platform.ilmiya.app/console/${space}/`
    : `https://platform.ilmiya.com/console/${space}/`;
export const redirectToAdmin = (space) =>
  localhost
    ? `http://localhost:3005/admin/${space}/`
    : development
    ? `https://platform.ilmiya.dev/admin/${space}/`
    : staging
    ? `https://platform.ilmiya.app/admin/${space}/`
    : `https://platform.ilmiya.com/admin/${space}/`;
export const redirectToAuthor = (space) =>
  localhost
    ? `http://localhost:3006/studio/${space}/`
    : development
    ? `https://studio.ilmiya.dev/studio/${space}/`
    : staging
    ? `https://studio.ilmiya.app/studio/${space}/`
    : `https://studio.ilmiya.com/studio/${space}/`;
export const redirectToLogin = (space) =>
  localhost
    ? `http://${space}.us.localhost:3001/`
    : development
    ? `https://${space}.myilmiya.dev/`
    : staging
    ? `https://${space}.myilmiya.app/`
    : `https://${space}.myilmiya.com/`;
export const redirect_uri = localhost
  ? "http://localhost:3000/signup/"
  : development
  ? "https://accounts.ilmiya.dev/signup/"
  : staging
  ? "https://accounts.ilmiya.app/signup/"
  : "https://accounts.ilmiya.com/signup/";
