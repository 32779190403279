import React, { useEffect, useState } from "react";
// import Breadcrumbs from "../Components2/Breadcrumbs";
// import ProfileCard from "../Components2/ProfileCard";
import ProfileForm from "../screens/ProfileForm";
import { Formik } from "formik";
import * as yup from "yup";
import {
  getProfileAsyncThunk,
  getUserAsyncThunk,
  updateUserAsyncThunk,
} from "../components/userSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loadingdata from "../screens/loadingdata";
import Breadcrumbs from "./Breadcrumbs";
import ProfileCard from "./ProfileCard";

export default function ProfileUpdateScreen() {
  const user = useSelector((s) => s.user.story);
  console.log("🚀 ~ ProfileUpdateScreen ~ user:", user)
  const [profileImage, setProfileImage] = useState(user?.photoURL);
  const [show, setShow] = React.useState(false);

  const [userDetails, setUserDetails] = React.useState({
    given_name: "",
    username: "",
    email: "",
    password: "",
    family_name: "",
    dob: "",
    // grade: "",
    gender: "",
    id_card_number: "",
    photoURL: "",
  });
  console.log("userDetails", userDetails);
  const [isLoading, setIsLoading] = React.useState(false);
  const { user_id } = useParams();
  const d = useDispatch();


  useEffect(() => {
    setProfileImage(user?.photoURL);
  }, [user]);

  const getProfileLoading = useSelector(
    (s) => s.user.loadings.getProfileAsyncThunk
  );
  console.log("user11", user);

  useEffect(() => {
    setIsLoading(getProfileLoading);
  }, [getProfileLoading]);

  const UserSchemaIn = yup.object().shape({
    given_name: yup.string().required("First name is required"),
    family_name: yup.string().required("Last name is required"),
    email: yup.string().email("Invalid email"),
    username: yup.string().required("Invalid Username"),
    password: yup.string().min(6, "Password must be at least 6 character long"),
    // grade: yup.string(),
    gender: yup.string(),
    dob: yup.string(),
    id_card_number: yup.string().required("ID required"),
  });

  const initialValues = {
    given_name: user?.given_name || "",
    username: user?.username || "",
    email: user?.email,
    password: user?.password || "",
    family_name: user?.family_name || "",
    dob: user?.dob || "",
    // grade: user?.grade,
    gender: user?.gender || "male",
    id_card_number: user?.id_card_number || "",
    photoURL: user?.photoURL  || "",
  };

  const handleChange1 = (e) => {
    if (e?.target) {
      setUserDetails({
        ...userDetails,
        [e.target.name]: e.target.value,
      });
    } else {
      setUserDetails({
        ...userDetails,
        photoURL: e
      })
    }
  };

  useEffect(() => {
    d(getProfileAsyncThunk());
  }, []);

  useEffect(() => {
    d(getUserAsyncThunk(user_id));
  }, [user_id]);

  const updateHandle = (userDetails) => {
    if (userDetails?.password && userDetails?.password?.length < 6) {
      toast.error("Password must be 6 character long");
      return;
    }
    if (!userDetails?.password) delete userDetails?.password;
    if (!userDetails?.dob) delete userDetails?.dob;
    // if (!userDetails?.grade) delete userDetails.grade;
    if (!userDetails?.gender) delete userDetails?.gender;

    console.log("🚀 ~ updateHandle ~ userDetails:", userDetails);
    d(
      updateUserAsyncThunk({
        data: {
          ...userDetails,
          email: undefined,
          photoURL: profileImage,
        },
        id: user?.user_id,
        callBack: () => {
          d(getUserAsyncThunk(user?.user_id));
          toast.success("Updated Profile Successfully");
          // window.location.reload();
        },
      })
    );
  };

  useEffect(() => {
    if (user) {
      setUserDetails({
        given_name: user?.given_name,
        username: user?.username,
        email: user?.email,
        password: user?.password || "",
        family_name: user?.family_name,
        dob: user?.dob,
        // grade: user?.grade || "1",
        gender: user?.gender || "male",
        id_card_number: user?.id_card_number || "0",
        photoURL: profileImage,
      });
    }
  }, [user, profileImage]);

  useEffect(() => {
    if (
      user?.given_name !== userDetails?.given_name ||
      user?.photoURL !== profileImage ||
      user?.family_name !== userDetails?.family_name ||
      user?.dob !== userDetails?.dob ||
      // user?.grade !== userDetails?.grade ||
      user?.gender !== userDetails?.gender ||
      user?.id_card_number !== userDetails?.id_card_number ||
      "" !== userDetails.password
    ) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [userDetails, user]);

  return (
    <div lassName="profile-page student-profile-page">
      {/* <Breadcrumbs
        text={"Profile"}
        svgimg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <mask
              id="mask0_6936_122625"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="25"
              height="25"
            >
              <rect
                x="0.966797"
                y="0.8125"
                width="24"
                height="24"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_6936_122625)">
              <path
                d="M12.9668 12.8125C11.8668 12.8125 10.9251 12.4208 10.1418 11.6375C9.35846 10.8542 8.9668 9.9125 8.9668 8.8125C8.9668 7.7125 9.35846 6.77083 10.1418 5.9875C10.9251 5.20417 11.8668 4.8125 12.9668 4.8125C14.0668 4.8125 15.0085 5.20417 15.7918 5.9875C16.5751 6.77083 16.9668 7.7125 16.9668 8.8125C16.9668 9.9125 16.5751 10.8542 15.7918 11.6375C15.0085 12.4208 14.0668 12.8125 12.9668 12.8125ZM4.9668 20.8125V18.0125C4.9668 17.4458 5.11263 16.925 5.4043 16.45C5.69596 15.975 6.08346 15.6125 6.5668 15.3625C7.60013 14.8458 8.65013 14.4583 9.7168 14.2C10.7835 13.9417 11.8668 13.8125 12.9668 13.8125C14.0668 13.8125 15.1501 13.9417 16.2168 14.2C17.2835 14.4583 18.3335 14.8458 19.3668 15.3625C19.8501 15.6125 20.2376 15.975 20.5293 16.45C20.821 16.925 20.9668 17.4458 20.9668 18.0125V20.8125H4.9668ZM6.9668 18.8125H18.9668V18.0125C18.9668 17.8292 18.921 17.6625 18.8293 17.5125C18.7376 17.3625 18.6168 17.2458 18.4668 17.1625C17.5668 16.7125 16.6585 16.375 15.7418 16.15C14.8251 15.925 13.9001 15.8125 12.9668 15.8125C12.0335 15.8125 11.1085 15.925 10.1918 16.15C9.27513 16.375 8.3668 16.7125 7.4668 17.1625C7.3168 17.2458 7.19596 17.3625 7.1043 17.5125C7.01263 17.6625 6.9668 17.8292 6.9668 18.0125V18.8125ZM12.9668 10.8125C13.5168 10.8125 13.9876 10.6167 14.3793 10.225C14.771 9.83333 14.9668 9.3625 14.9668 8.8125C14.9668 8.2625 14.771 7.79167 14.3793 7.4C13.9876 7.00833 13.5168 6.8125 12.9668 6.8125C12.4168 6.8125 11.946 7.00833 11.5543 7.4C11.1626 7.79167 10.9668 8.2625 10.9668 8.8125C10.9668 9.3625 11.1626 9.83333 11.5543 10.225C11.946 10.6167 12.4168 10.8125 12.9668 10.8125Z"
                fill="#2D2F31"
              />
            </g>
          </svg>
        }
      /> */}
      {/* <div className="container">
        <div className="row">
          <div className="col-12">
            <ProfileCard />
            <ProfileForm
              handleChange={handleChange}
              // user={userDetails}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              touched={touched}
              handleChange1={handleChange1}
            />
          </div>
        </div>
      </div> */}

      {isLoading ? (
        <Loadingdata />
      ) : (
          <div className="row">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={updateHandle}
              validationSchema={UserSchemaIn}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setFieldValue,
                values,
                errors,
                touched,
              }) => (
                <div className="col-12">

                  {/* <ProfileCard
                    photoURL={profileImage}
                    show={show}
                    user={user}
                    handleSubmit={handleSubmit}
                    setPhotoURL={setProfileImage}
                    handleChange1={handleChange1}
                  /> */}
                  <ProfileForm
                   photoURL={profileImage}
                   show={show}
                   user={user}
                   setPhotoURL={setProfileImage}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    // user={userDetails}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange1={handleChange1}
                  />
                </div>
              )}
            </Formik>
          </div>
        
      )}
    </div>
  );
}
