import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter } from "react-router-dom";
import { BaselimeRum } from "@baselime/react-rum";
import { store } from "./components/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <BaselimeRum apiKey="8kr75ykpah3NmWMTwqm4W1JsaANVBy2a2GPvutY9">
          <App />
          <ToastContainer
              theme="colored"
              position="bottom-center"
              style={{
                width: "100%",
                marginBottom: "0px",
                bottom: "0px",
                borderRadius: "0px",
              }}
              bodyStyle={{ marginBottom: "0px" }}
              toastClassName={"custom-toaster"}
            />
        </BaselimeRum>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
