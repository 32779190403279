export const reduceString = (s, n) => s?.length >= n ? s.substring(0, n) + "..." : s;
export const parseUrlFromModelAvatar = e => "https://content.ilmiyacdn.com/src/avatar/patch/" + encodeURIComponent(e);
export const parseUrlFromModelTrophy = e => "https://content.ilmiyacdn.com/assets/TROPHIES/PNG/" + encodeURIComponent(e);
export const parseUrlFromModelBadges = e => "https://content.ilmiyacdn.com/src/badges/PNG/" + encodeURIComponent(e);
export const parseUrlFromModelPatches = e => "https://content.ilmiyacdn.com/assets/PATCHES WITH BACKGOUND/1 Planetary Patch/" + encodeURIComponent(e);
export const parseUrlFromModel = (data) => {
    if (!data || !data.bucket || !data.name) {
      return null; // Return null if the required properties are not present
    }
  
    // Construct the link using the bucket and name properties
    const link = `https://content.ilmiyacdn.com/${data.name}`;
  
    return link;
  };