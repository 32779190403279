import React from "react";
import Terra from "../images/Terra.png";
import Blossom from "../images/Blossom.png";
import Climate from "../images/Climate.png";
import Design from "../images/Design.png";
import Earth from "../images/Earth.png";
import Interstellar from "../images/Interstellar.png";
import Marine from "../images/Marine.png";
import Monuments from "../images/Monuments.png";
import Pinnacle from "../images/Pinnacle.png";
import Planetary from "../images/Planetary.png";
import Timber from "../images/Timber.png";

function AvaterCard({ folder }) {
  const imageCateArray = [
    { name: "Terra", url: Terra },
    { name: "Blossom", url: Blossom },
    { name: "Climate", url: Climate },
    { name: "Design", url: Design },
    { name: "Earth", url: Earth },
    { name: "Interstellar", url: Interstellar },
    { name: "Marine", url: Marine },
    { name: "Monuments", url: Monuments },
    { name: "Pinnacle", url: Pinnacle },
    { name: "Planetary", url: Planetary },
    { name: "Timber", url: Timber },
  ];

  const matchingImage = imageCateArray?.find(
    (image) => image?.name === folder?.name
  );
  return (
    <div className="card-main-wrapper">
      <div className="img-wrapper">
        <img src={matchingImage?.url} alt={folder?.name} />
      </div>
      <div className="content-wrapper">
        <span className="strong">{folder?.name}</span>
      </div>
    </div>
  );
}

export default AvaterCard;
