import React from 'react'
import cross from "../images/croos.svg"
import site from "../images/site.svg"

function Site() {
  return (
    <div className='access-denied-page'>
       <div className='access-page-wrapper'>
        <div className='lock-img'>
            <img src={site} alt="" />
            <h2>Site Doesn't Exists</h2>
            <button>Go Back</button>
        </div>
        <div className='footer-wrapper'>
                <div className='img-inner'>
                    <img src={cross} alt="" />
                    <p className='my-2'>This page is not exist </p>
                </div>
            </div>
       </div>
    </div>
  )
}

export default Site
