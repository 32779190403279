import React from "react";

const EngagePageBottomHeader = ({ activeTab, getButtonClass }) => {
  return (
    <div className="header-bottom-container">
      <button
        className={`header-bottom-btn ${activeTab === "tab1" ? "active" : ""}`}
        onClick={() => getButtonClass("tab1")}
      >
        <span>Overview</span>
      </button>
      <span className="bar">|</span>
      <button
        className={`header-bottom-btn ${activeTab === "tab2" ? "active" : ""}`}
        onClick={() => getButtonClass("tab2")}
      >
        <span>Preview</span>
      </button>
    </div>
  );
};

export default EngagePageBottomHeader;
