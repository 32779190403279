import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { redirectToAuthor } from "../constants";
import { ApiRequests } from "../service/ApiRequests";
import { parseUrlFromModel } from "../utils/asset";
import { auth } from "../config/firebase";
import { getAuth } from "firebase/auth";
import Logout from "../components/logout";
import { Link } from "react-router-dom";
import { countUserEvent } from "../utils/utlis";
import plusIcon from '../images/add.svg'

AOS.init();
export default function CreateAuthor() {
  const _auth = getAuth();
  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState("");
  console.log("error:::::", error);
  const [validated, setValidated] = useState(false);
  const [step, setStep] = useState(0);
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState();
  const [uploadedContent, setUploadedContent] = useState();
  // const data = getOrganizationsMembership((s)=>s?.data?.data) ?? {};
  const { limit } = orgs;
  const [organization, setOrganization] = useState({
    name: "",
    description: "",
    banner_image_url: "https://content.ilmiyacdn.com/public/1708069689872-ilmiya_logo.png",
    profile_image_url: "https://content.ilmiyacdn.com/public/1708069689872-ilmiya_logo.png",

  });

  const getOrganizationsMembership = async () => {
    try {
      const params = {
        page: 1,
        limit: 1,
      };
      const { data } = await ApiRequests.getAuthorsMembership(params);
      console.log("data????????????????????:", data);
      setOrgs(data);
    } catch (error) {
      console.log("error:getOrganizationsMembership:", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setValidated(true);

    if (step === 1) {
      createOrganization();
    } else {
      setStep(step + 1);
    }
  };

  const createOrganization = async () => {
    setLoading(true);
    try {
      let content;
      // upload assets
      if (selectedImage && !uploadedContent) {
        const formData = new FormData();
        formData.append("files", selectedImage);
        content = await ApiRequests.createContent({
          data: formData,
          options: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              console.log("percentCompleted::", percentCompleted);
            },
            onDownloadProgress: (progressEvent) => {
              const progress =
                50 + (progressEvent.loaded / progressEvent.total) * 50;
              console.log("progress:onDownloadProgress::", progress);
            },
          },
        });
      }
      console.log("content:::", content);
      if (
        content &&
        content.data &&
        Array.isArray(content?.data) &&
        content?.data?.length
      ) {
        content = parseUrlFromModel(content.data[0]);
        console.log("content:::", content);
        setUploadedContent(content);
      }
      if (uploadedContent) {
        content = uploadedContent;
      }
      console.log("ApiRequests.createAuthor:::");
      // crfeate org
      const { data } = await ApiRequests.createAuthor({
        ...organization,
        ...(content ? { profile_image_url: content, } : {}),
        ...(content ? { banner_image_url: content, } : {}),
      });
      if (data) {
        const token = await fetchToken();
        // window.location = `http://${data.organization.name}.us.localhost:3001`
        window.location = `${redirectToAuthor(
          data.author_id
        )}?token=${token}`;
      }
      setLoading(false);
    } catch (error) {
      console.log("error:", error);
      setError(error?.response?.data);
      setLoading(false);
    }
  };
  useEffect(() => {
    getOrganizationsMembership();
  }, [limit]);
  const fetchToken = async () => {
    try {
      console.log("auth.currentUser", auth.currentUser);
      console.log("auth.currentUser", _auth.currentUser.accessToken);
      const { data } = await ApiRequests.getCustomToken({
        uid: auth.currentUser.uid,
      });
      console.log("data:::", data);
      return data.customToken;
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };
  console.log("user", auth.currentUser);
  useEffect(() => {
    fetchToken();
  }, []);

  return (
    <div>
      <div className="auth-body-outer" id="authBodyOuter">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {step === 0 && (
            <div className="organization-body">
              <Link to="/">
                <img
                  className="organization-logo"
                  src={require("../images/logo/logo.png")}
                  alt="logo"
                />
              </Link>
              <Logout />
              <div className="create-organization-card">
                <h1 className="mb-4">Create Publisher</h1>
                <div className="input-group">
                  <Form.Control
                    style={error?.message ? { color: "#EF4444" } : null}
                    name="name"
                    required
                    value={organization.name}
                    onChange={(e) => {
                      setOrganization({
                        ...organization,
                        name: e.target.value,
                      });
                      setError(null);
                    }}
                    placeholder="Publisher Name"
                    type="text"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please Enter display name!
                  </Form.Control.Feedback>
                </div>
                <div className="action-btns">
                  <div className="row">
                    <div className="col-6">
                      <Link to="/authors" className="btn btn-light">
                        Cancel
                      </Link>
                    </div>
                    <div className="col-6">
                      <button className="btn btn-primary">Next</button>
                    </div>
                  </div>
                </div>
              </div>
              {orgs && orgs.results && orgs.results.length > 0 && (
                <div className="organizations mt-5">
                  {orgs?.results?.length > 0 && (
                    <Link to="/authors">
                      <span style={{ color: "black" }} className="btn btn-light">
                        My Publisher
                      </span>
                    </Link>
                  )}
                </div>
              )}
            </div>
          )}
          {step === 1 && (
            <div className="organization-body">
              <Link to="/">
                <img
                  className="organization-logo"
                  src={require("../images/logo/logo.png")}
                  alt="logo"
                />
              </Link>
              <Logout />
              <div className="create-organization-card">
                {/* <h1>Choose Image</h1> */}
                {/* <h6>Please note, workspace names can't be changed.</h6> */}
                {/* <img
                  src={selectedImage ? URL.createObjectURL(selectedImage) : "https://content.ilmiyacdn.com/public/1708069689872-ilmiya_logo.png"}
                  alt="logo"
                  style={{ width: "100px", height: "100px", objectFit: "contain" }}
                /> */}
                <InputGroup className="input-group-text-box upload-image-box">


                  <Form.Label className="upload-label">
                    <Form.Control
                      style={error?.message ? { color: "#EF4444" } : null}
                      name="name"
                      onChange={(e) => {
                        console.log("e.target.value:::", e.target.value);
                        setSelectedImage(e.target.files[0]);
                      }}
                      placeholder="acme"
                      type="file"
                    />
                    {/* <InputGroup.Text id="basic-addon2">
                      Author Image
                    </InputGroup.Text> */}
                    <div className="icon-wrapper">
                      <img src={plusIcon} alt="plusIcon" />
                    </div>
                    <div className="text-wrapper">
                      <strong>Add image</strong>
                      <span>JPG, PNG. under 2MB</span>
                    </div>
                  </Form.Label>
                </InputGroup>
                {error?.message && (
                  <div className="alert alert-danger mt-3" role="alert">
                    {error?.message === "name already taken"
                      ? "This workspace name already exits. Please select another name."
                      : error?.message ===
                        "Display name should start with a letter and only consist of letters, digits, and hyphens with 4-20 characters."
                        ? "Display name should start with a letter and only consist of letters, digits, and hyphens with 4-20 characters."
                        : error?.message}
                  </div>
                )}
                <Form.Control.Feedback type="invalid">
                  Please Enter Valid organization name!
                </Form.Control.Feedback>
                <div className="action-btns">
                  <div className="row">
                    <div className="col-6">
                      <button
                        onClick={() => {
                          setStep(0);
                        }}
                        className="btn btn-light"
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="col-6">
                      <button onClick={() => { countUserEvent('Created_Author', { eventName: "Created_Author" }) }} disabled={loading} className="btn btn-primary">
                        {loading ? "Preparing..." : "Next"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>
      </div>
    </div>
  );
}
