import React from 'react'
import status from '../images/status-icon.svg'

const FooterLogin = () => {
  return (
    <div className='footer-organization-page'>
      <div className=' term-oraganization'>
        <ul>
          <li><a href="https://ilmiya.com/legal/terms-of-service">Terms </a></li>
          <li><a href="https://ilmiya.com/legal/privacy-policy">Privacy </a></li>
          <li><a href="https://support.ilmiya.com/hc/en-us">Support  </a></li>
        </ul>
      </div>
      <div className='main-status'>
        <a href="#" className='circle-status'>

          <img src={status} alt="img" />

          Status
        </a>
      </div>
    </div>
  )
}

export default FooterLogin
