import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { RotatingLines } from 'react-loader-spinner';
import { parseUrlFromModel, parseUrlFromModelAvatar, parseUrlFromModelBadges, parseUrlFromModelPatches, parseUrlFromModelTrophy } from '../../utils/asset';

const ImageskeletonLoader = ({
    src,
    imageStyle,
    imgClassName,
    lazzyImageStyle,
    defaultImageClass,
    imgHandeler,
    imgLoaderWidth,
    imgloaderStyle,
    title = true,
    parseUrl = true,
    parsePatchesUrl = false,
    parseBadgesUrl = false,
    parseTrophyUrl = false,
    parseAvatarUrl = false,
    defaultImgSrc = require("../../images/place-holder/iconsimg.jpg"), // Default image source as a prop
}) => {
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    console.log("src11111", src)

    // const defaultImgSrc = require("../../images/place-holder/iconsimg.jpg");


    const handleImageLoaded = () => {
        setLoading(false);
        setLoaded(true)
    };

    const handleImageError = () => {
        setLoading(false);
        setError(true);
    };

    return (
        <>
            {/* show loading when image on loading */}
            {loading && src && src !== null &&
                <div style={imgloaderStyle} >
                    <div>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width={imgLoaderWidth ? imgLoaderWidth : "96"}
                            visible={true}
                        />
                        {title && <h6>Image Loading</h6>}

                    </div>
                </div>
            }

            {/* show defeult image when Error image loading */}
            {src && error && <Image className={defaultImageClass} style={{ ...lazzyImageStyle, resize: "stretch" }} src={defaultImgSrc} />}

            {/* show defeult image when no image provided */}
            {src ? null : <Image className={defaultImageClass} style={{ ...lazzyImageStyle, resize: "stretch" }} src={defaultImgSrc} />}
            <Image
                style={loaded ? imageStyle : { display: 'none' }}
                src={
                    parseUrl
                        ? parseUrlFromModel(src)
                        : parsePatchesUrl
                            ? parseUrlFromModelPatches(src)
                            : parseBadgesUrl
                                ? parseUrlFromModelBadges(src)
                                : parseTrophyUrl
                                    ? parseUrlFromModelTrophy(src)
                                    : parseAvatarUrl
                                        ? parseUrlFromModelAvatar(src)
                                        : src}
                onLoad={handleImageLoaded}
                onError={handleImageError}
                onClick={imgHandeler}
                className={imgClassName}
            />
        </>
    );
};

export default ImageskeletonLoader;