import { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../config/firebase";
import { ApiRequests } from "../service/ApiRequests";
import Countly from "../config/countly";
import { countUserEvent } from "../utils/utlis";
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import FooterLogin from "./FooterLogin";

const RegisterForm = ({ setActiveStep }) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const googleProvider = new GoogleAuthProvider();
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
    newPassword: "",
    firstName: "",
    lastName: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (data.password !== data.newPassword) {
      setError("Passwords do not match");
      return;
    }
    setValidated(true);
    try {
      setLoading(true);
      const userCredential = await auth.createUserWithEmailAndPassword(
        data.email,
        data.password
      );
      const user = await userCredential.user.updateProfile({
        displayName: data.firstName + " " + data.lastName,
      });
      if (process.env.REACT_APP_COUNTLY_APP_ENABLED === 'true') {
        try {
          Countly.user_details({
            name: data.firstName + " " + data.lastName,
            username: userCredential.user.uid,
            email: data.email,
            picture: userCredential.user.photoURL,
          });
        } catch (error) {
          console.error("Error setting countly user details:", error);
        }
      }
      // console.log(userCredential);
      console.log({ user, userCredential });
      // await ApiRequests.createUser({
      //   displayName: userCredential?.user?._delegate?.displayName,
      //   email: userCredential?.user?._delegate?.email,
      //   emailVerified: userCredential?.user?._delegate?.emailVerified,
      // });
      setLoading(false);
      // navigate("/signup/profile");
      navigate("/signup/organization");
    } catch (error) {
      setError(
        error?.response?.data ? error?.response?.data?.message : error.message
      );
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    if (auth.currentUser) {
      navigate('/organizations');
    }
  }, [navigate, auth.currentUser])

  const signIn = (provider) => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        const user = result.user
        console.log("Google Signup", user)
        // console.log("Google Signup", token)
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        // const credential = GoogleAuthProvider.credentialFromError(error);
        console.log("Google Signup", error)
        console.log("Google Signup", errorCode)
        console.log("Google Signup", errorMessage)
        console.log("Google Signup", email)
        // console.log("Google Signup", credential)

      });
  }

  return (
    <Form validated={validated} onSubmit={handleSubmit}>
      <div className="auth-form">
        <>
          <div className="accounts-body">
            <div className="accounts-logo">
              <Link to='/'>
                <img
                  src={require("../images/logo/logo.png")} alt="logo"
                />
              </Link>
              {(location.pathname === '/signin') ?
                <Link to="/signup">Sign Up</Link>
                :
                <Link to="/signin">Login</Link>
              }
            </div>
            <div className="create-account-card">
              <h1>Create an ilmiya account</h1>
              <div className="form-card">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-group">
                      {/* <Form.Group controlId="email" className="mb-2"> */}
                      {/* <Form.Label>Email</Form.Label> */}
                      <label htmlFor="email">Email</label>
                      <Form.Control
                        name="email"
                        required
                        className="form-control"
                        value={data?.email}
                        onChange={(e) => {
                          setData({ ...data, email: e.target.value })
                          setError(null)
                        }}
                        type="email"
                        placeholder="Email"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid Email!
                      </Form.Control.Feedback>
                      {/* </Form.Group> */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-group">
                      <label htmlFor="firstName">First name</label>
                      <Form.Control
                        name="firstName"
                        required
                        className="form-control"
                        value={data?.firstName}
                        onChange={(e) => {
                          setData({ ...data, firstName: e.target.value });
                          setError(null)
                        }}
                        type="text"
                        placeholder="First Name"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid name!
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-group">
                      <label htmlFor="lastName">Last name</label>
                      <Form.Control
                        name="lastName"
                        required
                        className="form-control"
                        value={data?.lastName}
                        onChange={(e) => {
                          setData({ ...data, lastName: e.target.value });
                          setError(null)
                        }}
                        type="text"
                        placeholder="Last Name"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Enter Valid name!
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="create-label">
                      <label htmlFor="password">Password</label>
                      <div className="input-group">

                        <Form.Control
                          name="password"
                          className="form-control"
                          required
                          value={data?.password}
                          onChange={(e) => {
                            setData({ ...data, password: e.target.value });
                            setError(null)
                          }}
                          type={showPassword ? "text" : "password"}
                          placeholder="New Password"
                        />
                        {showPassword ? (
                          <i
                            onClick={() => setShowPassword(!showPassword)}
                            className="fa-light fa-eye-slash"
                          ></i>
                        ) : (
                          <i
                            onClick={() => setShowPassword(!showPassword)}
                            className="fa-light fa-eye"
                          ></i>
                        )}
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid organization name!
                        </Form.Control.Feedback>
                      </div>
                    </div>

                  </div>


                  <div className="col-lg-12">
                    <div className="create-label">
                      <label htmlFor="newPassword">Confirm password</label>
                      <div className="input-group">

                        <Form.Control
                          name="newPassword"
                          className="form-control"
                          required
                          value={data?.newPassword}
                          onChange={(e) => {
                            setData({ ...data, newPassword: e.target.value })
                            setError(null)
                          }}
                          type={showConfirmPassword ? "text" : "password"}
                          placeholder="Confirm Password"
                        />
                        {showConfirmPassword ? (
                          <i
                            onClick={() => setConfirmPassword(!showConfirmPassword)}
                            className="fa-light fa-eye-slash"
                          ></i>
                        ) : (
                          <i
                            onClick={() => setConfirmPassword(!showConfirmPassword)}
                            className="fa-light fa-eye"
                          ></i>
                        )}
                        <Form.Control.Feedback type="invalid">
                          Please Enter Valid organization name!
                        </Form.Control.Feedback>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              {/* <div className="already-have-account">
                By creating an account you agree to the{" "} <br/>
                <a href="https://ilmiya.com/legal/terms-of-service">Terms of Service</a> and{" "}
                <a href="https://ilmiya.com/legal/privacy-policy">Privacy Policy</a>.
              </div> */}
              <button onClick={() => countUserEvent('register', { eventName: 'create_ilmiya_account' })} disabled={loading} className="btn btn-primary mb-4">
                {loading ? <Spinner /> : "Create"}
              </button>
              <div className='other-options' style={{ marginTop: "5px" }}>
                <div className='row'>
                  <div className='col-12'>
                    <div className='option-btn' onClick={() => signIn(googleProvider)}>
                      <img src={require('../images/logo/Google.png')} />
                      <strong>Continue with Google</strong>
                    </div>
                  </div>

                </div>
              </div>
              <div className="already-have-account">
                Already have an ilmiya account?{" "}
                <Link onClick={() => countUserEvent('signIn', { eventName: 'Sign_In' })} to="/signin">
                  {" "}
                  Login <i className="fa-regular fa-arrow-right"></i>
                </Link>
              </div>
              {/* <div className="help-center mt-3">
                <a href="https://docs.ilmiya.com">Help</a>
                <span>|</span>
                <a href="https://ilmiya.com/legal/privacy-policy">Privacy</a>
                <span>|</span>
                <a href="https://ilmiya.com/legal/terms-of-service">Terms</a>
              </div> */}
              {error && (
                <div className="wrong-pass-card">
                  <i className="fa-solid fa-circle-xmark"></i>
                  <span>{error}</span>
                </div>
              )}
            </div>
          </div>
        </>
        <FooterLogin />
      </div>
    </Form>
  );
};
export default RegisterForm;
