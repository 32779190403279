import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

firebase.initializeApp(firebaseConfig);

// Initialize Analytics
let _analytics = null;
if (firebaseConfig.measurementId) {
  _analytics = firebase.analytics();
}
export const analytics = _analytics;
export const auth = firebase.auth();
export default firebase;
