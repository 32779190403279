import React from "react";
// import "../../lxp.scss";
import filterIcon from "../components/filter-icon.svg";

export default function FilterBar({ handleSearch, placeholder, widthStyle }) {
  const handleInputChange = (e) => {
    // Allow alphanumeric characters and hyphen
    const inputValue = e.target.value.replace(/[^a-zA-Z0-9 -]/g, "");
    handleSearch(inputValue);
  };

  return (

    <div className="filter-bar" style={widthStyle ? { width: '100%' } : {}}>
      <div className="form-group" style={widthStyle ? { width: '100%' } : {}}>
        <img src={filterIcon} alt="filterIcon" />
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          onInput={handleInputChange}
        />
      </div>
    </div>

  );
}
