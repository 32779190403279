import { useState } from "react";
import {
  Col,
  Form,
  Spinner,
} from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link, useNavigate } from "react-router-dom";
import { ApiRequests } from "../service/ApiRequests";
import { domain, redirectToLogin } from "../constants";
import Logout from "../components/logout";

AOS.init();
export default function Index() {
  const [space, setSpace] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      return;
    }

    setValidated(true);
    getData();
  };
  const [data, setData] = useState({
    name: "",
    displayName: "",
  });
  const getData = async () => {
    setLoading(true);
    setError("");
    try {
      const { data } = await ApiRequests.getOrganization(space);

      if (data?.name) window.location = redirectToLogin(data.name);
      if (data) {
        setData(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error::", error?.response);
      if (error?.response) {
        // The request was made and the server responded with a status code
        // outside the range of 2xx
        console.log(error?.response?.data);
        console.log(error?.response?.status);
        console.log(error?.response?.headers);
        setError(error?.response?.data?.message);
      } else if (error?.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error?.message);
        setError(error?.message);
      }
    }
  };

  return (
    <div>
      <div className="auth-body-outer">
        <div className="auth-header">
          <div className="container">
            <div
              className="header-row"
            >
              <img className="logo" src={require("../images/logo.png")} alt="logo" />
              <Logout />
            </div>
          </div>
        </div>
        <div className="auth-body">
          <div className="auth-form">
            <h2>Sign in to an existing Organization</h2>
            <h6>Enter your organization to sign in.</h6>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="field-row">
                <Form.Group as={Col} controlId="validationCustomUsername">
                  <Form.Label>Organization</Form.Label>
                  <div className="org-name-field">
                    <Form.Control
                      required
                      id="organizationInput" // Example ID
                      name="organizationName" // Example Name
                      className="organization-input" // Example ClassName
                      // value={space}
                      value={space.toLowerCase()}
                      onChange={(e) => {
                        // setSpace(e.target.value);
                        setSpace(e.target.value.toLowerCase());
                        setError("");
                      }}
                      type="text"
                      placeholder="acme"
                    />
                    <div className="lable2">
                      <span>.myilmiya.{domain}</span>
                    </div>
                  </div>

                  <Form.Control.Feedback type="invalid">
                    Please Enter An Organization Name.
                  </Form.Control.Feedback>
                  <Form.Control.Feedback className="text-danger">
                    Please Enter valid organization name.
                  </Form.Control.Feedback>
                  {error && (
                    <p className="text-danger">
                      {typeof error == "string" ? error : JSON.stringify(error)}
                    </p>
                  )}
                </Form.Group>
                <button
                  type="submit"
                  id="submitButton" // Example ID
                  name="submitButton" // Example Name
                  className="btn btn-primary mt-4 submit-button" // Example ClassName
                >
                  {loading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </Form>
            <br />
            <small>
              New to Ilmiya?
              <br />
              <Link to={"/signup"}>Create an organization.</Link>
            </small>
            <small>
              <br />
              Already have an ilmiya account?{" "}
              <br />
              <Link to="/signin">
                {" "}
                Login <i className="fa-regular fa-arrow-right"></i>
              </Link>
            </small>
          </div>
        </div>
        <div className="auth-footer">
          <div className="container">
            <div className="footer-body">
              <div className="nav">
                <div className="nav-item">
                  <a href="#" className="nav-link" id="supportLink">
                    Support
                  </a>
                </div>
              </div>
              <div className="nav">
                <div className="nav-item">
                  <a href="#" className="nav-link" id="privacyLink">
                    Privacy
                  </a>
                </div>
                <div className="nav-item">
                  <a href="#" className="nav-link" id="termsLink">
                    Terms
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
