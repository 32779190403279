import React from "react";
import { auth } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { countUserEvent } from "../utils/utlis";
import logo from "../images/logo/logo.png";
import Dropdown from "react-bootstrap/Dropdown";
import avatar from "../images/avatar.png";
import { redirect_uris } from "../constants";
import { Link, useLocation } from "react-router-dom";
import { LuUser2 } from "react-icons/lu";
import { MdOutlineSettings } from "react-icons/md";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { LuLogOut } from "react-icons/lu";
import { FaQuestion } from "react-icons/fa6";
import help from "../images/header.svg";

export default function Logout({children}) {
  const { currentUser: user } = auth;
  console.log("🚀 ~ Logout ~ user:", user?._delegate?.uid);
  const navigate = useNavigate();

  const accountHandler = () => {
    console.log("profile111");
    window.open(redirect_uris.Login, "_blank");
  };

  return user ? (
    <>
      <div className="header">
        <div className="row">
       <div className="col-6 col-md-6">
       <div className="section1">
          <Link to="/organizations">
          <img src={logo} alt="info" style={{ borderRadius: 4 }} />
          </Link>
        </div>
       </div>
        <div className="col-6 col-md-6">
        <div className="section2">
          <div className="starIcon">
            <a href="https://support.ilmiya.com/hc/en-us">
              <img src={help} alt="star" />
            </a>
          </div>
          <div>
            <button className="avatar-icon">
              <Dropdown direction="end">
                <Dropdown.Toggle id="dropdown-basic" style={{ border: "none" }}>
                  <img
                    style={{
                      width: "36px",
                      height: "36px",
                      borderRadius: "50%",
                    }}
                    src={user?.photoURL || avatar}
                    // alt="Avtar"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-end dropdown-menu-avtar">
                  <div className="menuItems">
                    <div className="dropdown-heading">
                      <img
                        style={{
                          width: "36px",
                          height: "36px",
                          borderRadius: "50px",
                        }}
                        src={user?.photoURL || avatar}
                        alt="Avtar"
                      />
                      <strong>{auth?.currentUser?.displayName}</strong>
                    </div>

                    <div className="mainItems">
                      <div className="items">
                        <div className="icons-1">
                          <LuUser2 />
                        </div>

                        <Link to={`/profile/${user?._delegate?.uid}`}>
                          <p>Profile</p>
                        </Link>
                      </div>

                      {/* <div className="items">
                        <div className="icons-1">
                          <MdOutlineAdminPanelSettings />
                        </div>
                        <a target="_blank" href={redirect_uris?.Admin}>
                          Admin
                        </a>
                      </div> */}
                    </div>
                    <div
                      className="logout-btn"
                      onClick={() => {
                        countUserEvent("Logout", { eventName: "Logout" });
                        auth.signOut().then(() => navigate("/"));
                      }}
                    >
                      <div className="icons-1">
                        <LuLogOut />
                      </div>
                      <p
                        onClick={() => {
                          countUserEvent("Logout", { eventName: "Logout" });
                          auth.signOut().then(() => navigate("/"));
                        }}
                      >
                        Logout
                      </p>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <div className="online-status"></div>
            </button>
          </div>
        </div>
        </div>
        </div>
      </div>
      {children}
    </>
  ) : (
    {children}
  );
}
