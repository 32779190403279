import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import userIcon from "../images/Staff.svg";


AOS.init();
export default function NoDataFound({ nodataTitle, imgTitle, subtitle }) {
  return (
    <div className="no-staff-section">
      <div className="no-staff-img-wrapper">
        <img src={imgTitle || userIcon} alt="No Data" className="mb-3" />
        <h3>{nodataTitle || "No DATA FOUND"}</h3>
        <p className="">{subtitle}</p>
      </div>
    </div>
  );
}
