import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();
export default function Loadingdata() {
    return (
        // <div className='no-data-outer'>
        // <img src={require('../images/loader.gif')} />
        // <h3>
        // Loading Data...
        // </h3>
        // </div>
        // <div className='loader-container-main'>
        //     <div v-if="loading" className="spinner">
        //         <div className="rect1"></div>
        //         <div className="rect2"></div>
        //         <div className="rect3"></div>
        //         <div className="rect4"></div>
        //         <div className="rect5"></div>
        //     </div>
        // </div>
        <div className='loading-div'>
        <div className='cube-loading-outer'>
            <div className="cube-wrapper">
                <div className="cube-folding">
                    <span className="leaf1"></span>
                    <span className="leaf2"></span>
                    <span className="leaf3"></span>
                    <span className="leaf4"></span>
                </div>
                <span className="loading" data-name="Loading">LOADING</span>
            </div>
        </div> 
        </div>
    );
}